<template>
  <b-row>
    <b-col cols="12" md="5">
      <odeme-tanimla />
    </b-col>
    <b-col cols="12" md="7">
      <banka-bilgileri />
    </b-col>
  </b-row>
</template>

<script>
import OdemeTanimla from './component/tanimla.vue';
import BankaBilgileri from './component/bankaBilgileri.vue';
export default {
  components: {
    OdemeTanimla,
    BankaBilgileri,
  },
  created() {
    this.$store.dispatch('kurTurleriListele');
  },
};
</script>

<style lang="scss" scoped></style>
