<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="openModal"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('closeModal', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <b-overlay :show="show" rounded="sm" no-fade>
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2">
            <h5 class="mb-0">Banka Tanımla</h5>
            <div>
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
          </div>
          <div class="p-1">
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form
                @submit.stop.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
                autocomplete="off"
                enctype="multipart/form-data"
              >
                <b-row>
                  <b-col cols="12" class="pt-1">
                    <validation-provider name="Banka Adı" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group label="Banka Adı">
                        <b-form-input
                          class="rounded-0"
                          ref="banka_adi"
                          v-model="form.banka_adi"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-1-live-feedback"
                        />

                        <b-form-invalid-feedback id="input-1-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider name="Iban" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group label="Iban">
                        <b-form-input
                          class="rounded-0"
                          ref="banka_iban"
                          v-model="form.banka_iban"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-1-live-feedback"
                        />

                        <b-form-invalid-feedback id="input-1-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Banka Swift Kodu">
                      <b-form-input
                        class="rounded-0"
                        ref="banka_swift_kodu"
                        v-model="form.banka_swift_kodu"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Banka Kodu">
                      <b-form-input
                        class="rounded-0"
                        ref="banka_kodu"
                        v-model="form.banka_kodu"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Banka Adres">
                      <b-form-input
                        class="rounded-0"
                        ref="banka_adresi"
                        v-model="form.banka_adresi"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider name="Hesap Türü" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group label="Hesap Türü" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                        <v-select
                          v-model="form.banka_hesap_turu"
                          :options="getKurTurleri"
                          :reduce="(kur) => kur.banka_kodu"
                          label="banka_kodu"
                          :clearable="false"
                          :class="{ 'is-invalid': !!validationContext.errors[0] }"
                          :state="getValidationState(validationContext)"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="d-flex justify-content-between align-items-center py-1">
                      <label class="font-weight-bold h6">Kullanım Durumu</label>
                      <b-form-checkbox :checked="form.statu" v-model="form.statu" switch />
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <b-button squared block type="submit" variant="primary">
                      <feather-icon icon="CheckSquareIcon" class="mr-50" />
                      <span class="align-middle">{{ form._id == null ? $t('global.ekle') : $t('global.guncelle') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <b-button squared block @click="hide">
                      <feather-icon icon="XSquareIcon" class="mr-50" />
                      <span class="align-middle">{{ $t('global.iptal') }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import Cleave from 'vue-cleave-component';
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
export default {
  components: {
    Cleave,
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['getKurTurleri']),
  },
  data() {
    return {
      show: false,
      form: {
        _id: null,
        banka_adi: null,
        banka_iban: null,
        banka_swift_kodu: null,
        banka_kodu: null,
        banka_adresi: null,
        banka_hesap_turu: null,
        statu: true,
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    onSubmit() {
      this.show = true;
      const basrUrl = this.form._id == null ? 'odemeBankaBilgiEkle' : 'odemeBankaBilgiGuncelle';
      this.$store
        .dispatch(basrUrl, this.form)
        .then((res) => {
          if (res.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: this.form._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
              },
            });
            this.$emit('updateToValue', true);
            this.show = false;
          }
        })
        .catch((err) => {
          if (err.success === false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text: err.message,
              },
            });
            this.show = false;
          }
        });
    },
  },
  watch: {
    updateData(val) {
      if (val) {
        this.form._id = val._id;
        this.form.banka_adi = val.banka_adi;
        this.form.banka_iban = val.banka_iban;
        this.form.banka_swift_kodu = val.banka_swift_kodu;
        this.form.banka_kodu = val.banka_kodu;
        this.form.banka_adresi = val.banka_adresi;
        this.form.banka_hesap_turu = val.banka_hesap_turu;
        this.form.statu = val.statu;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
