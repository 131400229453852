<template>
  <b-row>
    <b-col cols="12" md="6">
      <b-card-normal title="Diger Ayarlar" :showLoading="show">
        <template v-slot:body>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-between align-items-center py-1">
                    <label for="statu" class="font-weight-bold h6">Varsayılan Kur</label>
                    <v-select
                      v-model="form.varsayilan_kur"
                      :options="kurlar"
                      :reduce="(kur) => kur.banka_kodu"
                      label="birim"
                      :clearable="false"
                      style="width: 200px"
                      class="invoice-filter-select d-block"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-between align-items-center py-1">
                    <label for="statu" class="font-weight-bold h6">Rezervasyon işleminde üyelik zorunluluğu</label>
                    <b-form-checkbox
                      :checked="form.rezervasyon_uye_zorunluluk"
                      v-model="form.rezervasyon_uye_zorunluluk"
                      switch
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-between align-items-center py-1">
                    <label for="statu" class="font-weight-bold h6">Ödeme sonrasında bildirim gönderme</label>
                    <b-form-checkbox
                      :checked="form.odemeden_sonra_bildirim_gonderme"
                      v-model="form.odemeden_sonra_bildirim_gonderme"
                      switch
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-between align-items-center py-1">
                    <label for="statu" class="font-weight-bold h6">Üye olmadan yorum yapabilme</label>
                    <b-form-checkbox :checked="form.uye_olmadan_yorum_yapma" v-model="form.uye_olmadan_yorum_yapma" switch />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-between align-items-center py-1">
                    <label for="statu" class="font-weight-bold h6">Hatırlatma maili kaç gün önceden gönderilsin</label>
                    <b-form-input v-model="form.hatirlatma_mail_gun" class="rounded-0" style="width: 200px" />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-between align-items-center py-1">
                    <label for="statu" class="font-weight-bold h6">Hatırlatma maili kime gönderilsin</label>
                    <b-form-input v-model="form.hatirlatma_maili" class="rounded-0" style="width: 200px" />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button squared block type="submit" :disabled="!firmaKNo" variant="primary">
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="align-middle">{{ form._id == null ? 'Ekle' : 'Güncelle' }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </template>
      </b-card-normal>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
export default {
  components: {
    BCardNormal,
    vSelect,
  },
  setup() {
    const expo = {};
    const toast = useToast();
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.kurlar = computed(() => store.getters.getKurTurleri);
    expo.show = ref(false);
    expo.form = ref({
      _id: null,
      varsayilan_kur: null,
      rezervasyon_uye_zorunluluk: false,
      odemeden_sonra_bildirim_gonderme: false,
      uye_olmadan_yorum_yapma: false,
      hatirlatma_mail_gun: 5,
      hatirlatma_maili: null,
    });

    const handlerOdemeConfig = async () => {
      expo.show.value = true;
      await store.dispatch('kurTurleriListele');
      await store.dispatch('digerAyarlariGetir').then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
        expo.show.value = false;
      });
    };

    handlerOdemeConfig();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    expo.onSubmit = () => {
      expo.show.value = true;
      store
        .dispatch('digerAyarlariEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            expo.show.value = false;
          }
        })
        .catch((err) => {
          expo.show.value = false;
          if (err.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
